import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Link } from '@mui/material';

type CategoryItemProps = {
  strapiId: number;
  text: string;
  style?: Record<string, unknown>;
}

const CategoryItem = ({ strapiId, text, style }: CategoryItemProps) => (
  <li css={{ padding: '0.2em 0', ...style, listStyle: 'none' }}>
    <Link
      href={`/catalogue/category/${strapiId}`}
      underline="hover"
      sx={{ textUnderlineOffset: '0.3rem' }}
    >
      {text}
    </Link>
  </li>
);

type SidebarData = {
  strapiId: number;
  text: string;
  depth: number;
  isHidden: boolean;
  isSelected: boolean;
}

const CategoriesToSidebarData = (data: GatsbyTypes.SidebarCategoriesQuery, currentCategory: number) => {
  const allPrimaryCategories = data.allStrapiCategories.nodes.filter(c => !c.parent_category);
  const allSubcategories = data.allStrapiCategories.nodes.filter(c => c.parent_category);
  const category = data.allStrapiCategories.nodes.find(c => c.strapiId === currentCategory);
  const whitelist = category === undefined
    ? []
    : [category.strapiId, category.parent_category?.id, category.parent_category?.parent_category];

  const sidebarData: SidebarData[] = [];
  allPrimaryCategories.forEach(c => {
    if (c.name == 'Oclee & Son Ltd') {
      return;
    }

    sidebarData.push({
      strapiId: c.strapiId,
      text: c.name,
      depth: 0,
      isHidden: false,
      isSelected: c.strapiId === currentCategory,
    });

    allSubcategories.filter(sc => sc.parent_category.id === c.strapiId).forEach(sc => {
      sidebarData.push({
        strapiId: sc.strapiId,
        text: `↳ ${sc.name}`,
        depth: 1,
        isHidden: !whitelist.find(c => sc.parent_category.id === c),
        isSelected: sc.strapiId === currentCategory,
      });

      allSubcategories.filter(ssc => ssc.parent_category.id === sc.strapiId).forEach(ssc => {
        sidebarData.push({
          strapiId: ssc.strapiId,
          text: `↳ ${ssc.name}`,
          depth: 2,
          isHidden: !whitelist.find(c => ssc.parent_category.id === c),
          isSelected: ssc.strapiId === currentCategory,
        });
      });
    });
  });

  return sidebarData;
};

type CatalogueSidebarProps = {
  currentCategory: number;
};

export default function CatalogueSidebar({ currentCategory }: CatalogueSidebarProps): JSX.Element {
  const data = useStaticQuery<GatsbyTypes.SidebarCategoriesQuery>(
    graphql`
      query SidebarCategories {
        allStrapiCategories(
          filter: {
            sort_order: {gte: 0}
          }
          sort: {fields: sort_order}
        ) {
          nodes {
            strapiId
            name
            parent_category {
              id
              parent_category
            }
          }
        }
      }
    `
  );

  const sidebarData = CategoriesToSidebarData(data, currentCategory);

  return (
    <ul css={{ fontSize: '1.1em', margin: '0', padding: '0' }}>
      {
        sidebarData
          .filter(c => !c.isHidden)
          .map(c => (
            <CategoryItem
              key={c.strapiId}
              strapiId={c.strapiId}
              text={c.text}
              style={{
                fontFamily: '"Amiri", serif',
                fontWeight: c.isSelected ? 'bold' : 'normal',
                marginLeft: `${c.depth}em`,
              }}
            />
          ))
      }
    </ul>
  );
}
