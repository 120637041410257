import * as React from 'react';
import CatalogueItem from '../../../components/CatalogueItem';
import CatalogueCategory from '../../../components/CatalogueCategory';
import CatalogueLayout from '../../../components/CatalogueLayout';
import { graphql } from 'gatsby';

type CategoryProps = {
  data: GatsbyTypes.ThisCategoryAndSubcategoriesOrProductsQuery;
  params: { strapiId: string };
}

export default function Category({ data, params: { strapiId } }: CategoryProps): JSX.Element {
  const grandParentID = data.thisCategory.parent_category?.parent_category;
  const grandParentName = data.allStrapiCategories.nodes
    .filter((n) => n.strapiId === grandParentID)[0]?.name;
  const parentID = data.thisCategory.parent_category?.id;
  const parentName = data.thisCategory.parent_category?.name;
  const thisID = data.thisCategory.strapiId;
  const thisName = data.thisCategory.name;

  const pageTitle = [data.thisCategory.name, grandParentName].filter((part) => part).join(", ");
  const breadcrumbs = [
    {text: 'Catalogue', href: '/catalogue/'},
    {text: grandParentName, href: `/catalogue/category/${grandParentID}`},
    {text: parentName, href: `/catalogue/category/${parentID}`},
    {text: thisName, href: `/catalogue/category/${thisID}`},
  ].filter((crumb) => crumb.text);

  return (
    <CatalogueLayout
      currentCategory={Number(strapiId)}
      title={`${pageTitle} | Oclee & Son`}
      breadcrumbs={breadcrumbs}
    >
      {
        data.subcategories.nodes.map(c => {
          return <CatalogueCategory
            key={c.strapiId}
            href={`/catalogue/category/${c.strapiId}`}
            name={c.name}
            image={c.image.localFile.childImageSharp?.gatsbyImageData}
          />;
        })
      }
      {
        data.allStrapiProducts.nodes.map(p => {
          return <CatalogueItem
            key={p.strapiId}
            href={`/catalogue/product/${p.strapiId}`}
            name={p.name}
            price={p.price}
            image={p.image.localFile.childImageSharp?.gatsbyImageData}
          />;
        })
      }
    </CatalogueLayout>
  );
}

export const query = graphql`
query ThisCategoryAndSubcategoriesOrProducts($strapiId: Int!) {
  thisCategory: strapiCategories(strapiId: {eq: $strapiId}) {
    strapiId
    name
    parent_category {
      id
      name
      parent_category
    }
  }

  subcategories: allStrapiCategories(
    filter: {
      parent_category: {id: {eq: $strapiId}},
      sort_order: {gte: 0}
    }
    sort: {fields: sort_order}
  ) {
    nodes {
      strapiId
      name
      image {
        localFile {
          childImageSharp {
            gatsbyImageData(
              height: 150
              placeholder: BLURRED
              formats: [AUTO, WEBP]
              quality: 100
            )
          }
        }
      }
    }
  }

  allStrapiCategories(
    filter: {
      sort_order: {gte: 0}
    }
    sort: {fields: sort_order}
  ) {
    nodes {
      strapiId
      name
    }
  }

  allStrapiProducts(
    filter: {
      category: {id: {eq: $strapiId}},
      sort_order: {gte: 0}
    }
    sort: {fields: sort_order}
  ) {
    nodes {
      strapiId
      name
      price
      image {
        localFile {
          childImageSharp {
            gatsbyImageData(
              height: 150
              placeholder: BLURRED
              formats: [AUTO, WEBP]
              quality: 100
            )
          }
        }
      }
    }
  }
}
`;
