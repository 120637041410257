import * as React from 'react';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { Card, CardActionArea, CardContent, CardMedia, Typography } from '@mui/material';

const displayPrice = (price: number) =>
  Math.abs(price - Math.round(price)) < 0.005 ?
    Math.round(price).toString() :
    price.toFixed(2);

type CatalogueItemProps = {
  href: string,
  name: string;
  price?: number;
  image: IGatsbyImageData;
}

export default function CatalogueItem({ href, name, price, image }: CatalogueItemProps): JSX.Element {
  return (
    <Card sx={{ maxWidth: '15rem' }}>
      <CardActionArea sx={{ height: '100%' }} href={href}>
        <CardMedia sx={{ textAlign: 'center', paddingTop: '1rem'}}>
           <GatsbyImage
             image={image}
             alt=""
             loading="lazy"
             objectFit="contain"
             css={{height: 140}}
           />
        </CardMedia>
        <CardContent sx={{ height: '100%' }}>
          <Typography variant="body1" component="h2">
            {name}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            { price > 0 ? `£${displayPrice(price)}` : "POA" }
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
