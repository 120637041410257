import * as React from 'react';
import Layout from './Layout';
import CatalogueSidebar from './CatalogueSidebar';
import { Box, Container, Stack, Typography } from '@mui/material';
import Header from './Header';
import Footer from './Footer';

type CatalogueLayoutProps = {
  title: string;
  breadcrumbs: { text: string, href: string }[];
  currentCategory: number;
  children: React.ReactNode;
};

export default function CatalogueLayout({ title, breadcrumbs, currentCategory, children }: CatalogueLayoutProps): JSX.Element {
  return (
    <Layout title={title}>
      <Stack minHeight="100vh">
        <Header page="catalogue" />
        <Box flex={1}>
          <Container maxWidth="lg">
            <Typography
              variant="h1"
              fontSize="1.5rem"
              marginBottom="1em"
            >
              {
                breadcrumbs.map((crumb) => (
                  <Box
                    key={crumb.href}
                    component="a"
                    href={crumb.href}
                    color="text.primary"
                    display="inline-block"
                    whiteSpace="nowrap"
                    sx={{
                      "&:after": {
                        content: '">"',
                        padding: "0 0.8rem",
                        display: 'inline-block',
                        textDecoration: 'none',
                      },
                      "&:last-child:after": {
                        content: '""',
                      },
                      "@supports (-moz-appearance:none)": {
                        textUnderlineOffset: '3px',
                      }
                    }}
                  >
                    {crumb.text}
                  </Box>
                ))
              }
            </Typography>
            <Stack direction="row">
              <Box minWidth="13em" maxWidth="13em" mr="1em" display={['none', null, 'block']}>
                <CatalogueSidebar currentCategory={currentCategory} />
              </Box>
              <Box display="flex" flexGrow={3} justifyContent={["center", null, "left", "center"]} mb={4}>
                <Box
                  display="grid"
                  gridTemplateColumns={['1fr', 'repeat(2, 1fr)', 'repeat(3, 1fr)']}
                  gridAutoRows="1fr"
                  gap="0.7rem"
                >
                  { children }
                </Box>
              </Box>
              <Box minWidth="13em" maxWidth="13em" ml="1em" display={['none', null, null, 'block']} />
            </Stack>
          </Container>
        </Box>
        <Footer maxWidth="752px" />
      </Stack>
    </Layout>
  );
}
